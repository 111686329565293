<template>
  <div class="app">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
const isAdmin = useState<boolean>("isAdmin")
const isReseller = useState<boolean>("isReseller", () => false)
const resellerId = useState<number>("resellerId")
const resellerOrgId = useState<number>("resellerOrgId")
// This will probably need changing but works for now with limited resellers
const brand = useState<string>("assetBrandFolder", () => {
  if (window.location.origin.includes("alwaysconnectsolutions")) {
    isReseller.value = false
    return "acs"
  } else if (window.location.origin.includes("xcape")) {
    resellerId.value = 2
    resellerOrgId.value = 476
    isReseller.value = true
    return "xcape"
  } else {
    // default to ACS branding if no route found or localhost
    return "acs"
  }
})

useHead({
  title: brand.value == "xcape" ? "Xcape Portal" : "ACS Portal",
  link: [
    {
      rel: "icon",
      type: "image/png",
      href:
        brand.value === "xcape"
          ? "/brand/xcape/logo-small.png?type=image/png"
          : "/brand/acs/logo-small.png?type=image/png"
    }
  ]
})

onMounted(async () => {
  useState("billingCyclesBack", () => {
    const local = localStorage.getItem("billingCyclesBack")
    return Number(local) || 0
  })
  useState("currentDateId", () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0") // January is 0, hence +1. Pad with 0 for single-digit months.
    const day = date.getDate().toString().padStart(2, "0") // Pad with 0 for single-digit days.

    const formattedDate = `${year}${month}${day}`
    return parseInt(formattedDate, 10)
  })
  useState("usageType", () => {
    const local = localStorage.getItem("usageType")
    return isAdmin && !isReseller ? local || "All" : "All"
  })
})
</script>

<style lang="scss">
.app {
  font-family: "Open Sans", --cui-font-sans-serif;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.15s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}

.carrier-card {
  .header {
    .chartjs-tooltip {
      display: none;
    }
  }
}

.data-table-filter.form-control:focus {
  border-color: #b1b7c1;
  box-shadow: 0 0 0 0.25rem rgb(5, 118, 225, 0.25);
}
</style>
